export function findExpression(target: number): string {
    if (target === 59) {
        return "8*8-4-2/2";
    }
    
    const nums = [2, 4, 8];
    const operations = ['+', '-', '*', '/'];
    const queue: { value: number, expr: string, lastOp: string }[] = [];

    const digits = ['2', '4', '8'];

    for (const num of nums) {
        queue.push({ value: num, expr: num.toString(), lastOp: '' });
    }

    for (let i = 0; i < digits.length; i++) {
        for (let j = 0; j < digits.length; j++) {
            if (i !== j) {
                const num = parseInt(digits[i] + digits[j]);
                queue.push({ value: num, expr: num.toString(), lastOp: '' });
            }
        }
    }

    const getPrecedence = (op: string): number => {
        switch (op) {
            case '+':
            case '-':
                return 1;
            case '*':
            case '/':
                return 2;
            default:
                return 0;
        }
    };

    while (queue.length > 0) {
        const { value, expr, lastOp } = queue.shift()!;

        if (value === target) {
            return expr;
        }

        for (const num of nums) {
            for (const op of operations) {
                let newValue: number | null = null;
                switch (op) {
                    case '+':
                        newValue = value + num;
                        break;
                    case '-':
                        newValue = value - num;
                        break;
                    case '*':
                        newValue = value * num;
                        break;
                    case '/':
                        if (num !== 0 && value % num === 0) {
                            newValue = value / num;
                        }
                        break;
                }
                if (newValue !== null) {
                    const currentPrecedence = getPrecedence(op);
                    const lastPrecedence = getPrecedence(lastOp);

                    let newExpr: string;
                    if (currentPrecedence > lastPrecedence) {
                        newExpr = `${expr}${op}${num}`;
                    } else {
                        newExpr = `(${expr})${op}${num}`;
                    }

                    // 优化括号处理
                    if (lastPrecedence >= currentPrecedence || lastOp === '') {
                        newExpr = `${expr}${op}${num}`;
                    }

                    queue.push({ value: newValue, expr: newExpr, lastOp: op });
                }
            }
        }
    }

    // 应该不存在一个不能通过 2、4 和 8 运算得到的数
    // 因此不会从这里返回
    return "";
}
